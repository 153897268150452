import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { workLinkLabel } from "../../components/work/work-card"
import BlockRichText from "../../components/block-rich-text"
import FreeConsultationForm from "../../components/free-consultation-form"
import Slider from "react-slick/lib/slider"
import { useRef } from "react"
import SliderNav from "../../components/slider/slider-nav"
import { appendDots } from "../../components/slider/slider-dots"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

function Improvements({
  data,
  isLayoutDefault,
  visible = true,
}) {
  if (!visible) {
    return null;
  }

  const wrapperClassName = [
    isLayoutDefault ? 'lg:basis-1/2' : '',
    'space-y-6',
  ]

  const itemClassName = [
    !isLayoutDefault ? 'sm:grid-cols-2' : '',
    'grid',
    'gap-8',
  ]

  return (
    <section className={wrapperClassName.join(' ')}>
      <h2 className="text-[38px] border-b-[1px] border-concrete pb-4">what we've done</h2>
      <div className={itemClassName.join(' ')}>
        { data.map(({
          description,
          id,
          title,
          body,
        }) => (
          <div key={id} className="flex flex-col space-y-2">
            <h5 className="font-bold">{title}</h5>
            <p>+ {description}</p>
            <BlockRichText data={body.data.body} />
          </div>
        ))}
      </div>
    </section>
  )
}

function Features({
  data,
  isLayoutCompact,
  visible = true,
}) {
  if (!visible || data.length === 0) {
    return null;
  }

  const wrapperClassName = [
    'border-[1px]',
    'border-primary',
    'leading-none',
    'text-center',
    ...(isLayoutCompact ? [
      'md:flex',
      'w-full',
    ] : []),
  ];

  const itemClassName = [
    'text-primary',
    'p-6',
    'border-t-[1px]',
    'border-primary',
    'first:border-0',
    ...(isLayoutCompact ? [
      'md:border-t-0',
      'md:border-l-[1px]',
      'md:basis-1/2',
    ] : []),
  ];

  return (
    <div className={wrapperClassName.join(' ')}>
      { data.map(({
        body,
        highlight,
        id,
        title,
      }) => (
        <div key={id} className={itemClassName.join(' ')}>
          <p className="font-bold mb-2">{title}</p>
          <p className="text-[64px]">{highlight}</p>
          <BlockRichText  data={body.data.body} />
        </div>
      ))}
    </div>
  )
}

function Links({
  data,
}) {
  if (data.length === 0) {
    return null;
  }

  const { t } = useTranslation()

  return (
    <div className="grid grid-flow-col gap-4">
    { data.map(({
      id,
      type,
      url,
    }) => (
      <a
        className="bg-primary font-bold text-white inline-block p-4 text-center hover:bg-secondary"
        key={id}
        href={url}
        target="_blank"
      >
        {t(workLinkLabel[type]) || t('visit site')}
      </a>
    ))}
  </div>
  )
}

function Screenshots({
  data,
  forwardRef,
  isLayoutMobile,
}) {
  const className = [
    'slider',
    'w-full',
    'top-0',
    'left-0',
    'right-0',
    'mx-auto',
    'rounded-[1.5rem]',
    'overflow-hidden',
    ...(isLayoutMobile ? [
      '!absolute',
      'w-[308px]',
      'mt-5',
    ] : []),
  ]
  
  const sliderSettings = {
    ref: forwardRef,
    className: className.join(' '),
    arrows: false,
    dots: !isLayoutMobile,
    appendDots,
  }

  if (data.length === 1) {
    const { alternativeText, id, localFile } = data[0];

    return (
      <GatsbyImage
        className={className.join(' ')}
        key={id}
        image={getImage(localFile)}
        alt={alternativeText}
      />
    )
  }

  return (
    <Slider key="work-slick" {...sliderSettings}>
      { data.map(({
        alternativeText,
        id,
        localFile,
      }) => (
        <GatsbyImage
          key={id}
          image={getImage(localFile)}
          alt={alternativeText}
        />
      ))}
    </Slider>
  )
}

function ScreenshotsSection({
  data,
  isLayoutMobile,
  isLayoutDefault,
}) {
  const slider = useRef(null)
  const showSliderNav = data.length > 1;

  const sectionClassName = [
    ...(isLayoutDefault ? [
      'row-span-2',
    ] : []),
    'lg:basis-1/2',
    'lg:w-[50%]',
    'lg:-order-1',
    'lg:!mt-0',
    'lg:pr-10',
  ]

  const sliderNavClasses = [
    'absolute',
    'inline-flex',
    'items-center',
    'justify-center',
    'bg-secondary',
    'text-center',
    'text-white',
    'w-14',
    'h-14',
    'top-0',
    'bottom-0',
    'm-auto',
    'z-[1]',
  ]

  return (
    <section className={sectionClassName.join(' ')}>
      <div className="relative">
        <SliderNav
          className={[
            ...sliderNavClasses,
            'mr-4',
            '-left-5',
          ].join(' ')}
          onClick={() => slider!.current.slickPrev()}
          direction="left"
          visible={showSliderNav}
        />
        <div className="relative flex justify-center">
          <PhoneMockup visible={isLayoutMobile} />
          <Screenshots {...{
              forwardRef: slider,
              data,
              isLayoutMobile,
            }}
          />
        </div>
        <SliderNav
          className={[
            ...sliderNavClasses,
            '-right-5'
          ].join(' ')}
          onClick={() => slider!.current.slickNext()}
          direction="right"
          visible={showSliderNav}
        />
      </div>
    </section>
  )
}

function PhoneMockup({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div>
      <StaticImage
        className="pointer-events-none"
        src="./iphone.png"
        alt="iphone"
        layout="fixed"
        placeholder="tracedSVG"
      />
    </div>
  )
}

function ViewMoreLink({
  alignment = 'center',
  visible = true,
}) {
  if (!visible) {
    return null;
  }

  const { t } = useTranslation()

  return (
    <div className={`text-center lg:text-${alignment} py-20 flex-1`}>
      <Link className="button-main" to="/works">{t('view other works')}</Link>
    </div>
  )
}

function WorkPost({ data, pageContext }) {
  const {
    strapiGlobal,
    strapiWork
  } = data
  const {
    body,
    feature,
    improvement,
    layout,
    link,
    screenshots,
    seo: workSeo,
    title,
  } = strapiWork

  const isLayoutCompact = layout === 'compact';
  const isLayoutDefault = layout === 'default';
  const isLayoutMobile = layout === 'mobile';

  const seo = {
    metaTitle: title,
    lang: pageContext.strapiContext,
    ...(workSeo || {}),
  }

  return (
    <Layout as="work">
      <Seo seo={seo} />
      <main className="container mt-10 sm:mt-20 space-y-12 lg:flex lg:flex-wrap">
        <section className="space-y-6 lg:basis-1/2">
          <h1 className="font-bold text-primary lg:text-[50px]">{title}</h1>
          <Links data={link} />
          <BlockRichText data={body.data.body} />
          <Features {...{ data: feature, isLayoutCompact, visible: !isLayoutCompact }} />
          <Improvements {...{ data: improvement, isLayoutDefault, visible: isLayoutDefault }} />
          <ViewMoreLink alignment="left" visible={isLayoutDefault} />
        </section>
        <Features {...{ data: feature, isLayoutCompact, visible: isLayoutCompact }} />
        <Improvements {...{ data: improvement, isLayoutDefault, visible: !isLayoutDefault }} />
        <ScreenshotsSection {...{ data: screenshots, isLayoutMobile, isLayoutDefault }} />
        <ViewMoreLink visible={!isLayoutDefault} />
      </main>
      <FreeConsultationForm {...{ contactDetails: strapiGlobal.contactDetails }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query (
    $language: String!
    $slug: String
    $strapiLangKey: String!
  ) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      ...Locales
    }
    strapiGlobal(locale: {eq: $strapiLangKey}) {
      ...ContactDetails
    }
    strapiWork(slug: { eq: $slug }) {
      id
      seo {
        ...Seo
      }
      title
      layout
      excerpt {
        data {
          excerpt
        }
      }
      body {
        data {
          body
        }
      }
      services {
        slug
      }
      feature {
        id
        title
        highlight
        body {
          data {
            body
          }
        }
      }
      improvement {
        id
        title
        description
        body {
          data {
            body
          }
        }
      }
      link {
        id
        type
        url
      }
      screenshots {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
            )
          }
        }
        alternativeText
      }
    }
  }
`

export default WorkPost
