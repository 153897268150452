import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import BlockRichText from '../block-rich-text'

type Props = {
  excerpt: any;
  featureImage: any;
  link: any;
  odd: boolean;
  services: any[];
  slug: string;
  title: string;
}

export const workLinkLabel = {
  appStore: "app store",
  playStore: "android",
}

function replaceLink(url) {
  if(url.includes('https:')){
     return url.replace('https://','').replace('www.','')
  }else if(url.includes('http:')){
          return url.replace('http://','').replace('www.','')
  }
  return url
}

function HLine({
  className,
}: { className?: string }) {
  const defaultClassName = 'h-[1px] bg-primary w-full absolute top-0 left-0';

  return (
    <div className={`${defaultClassName} ${className}`} />
  )
}

function VLine({
  className,
}: { className?: string }) {
  const defaultClassName = 'absolute bg-primary w-[1px] h-full lg:h-[120%] group-odd:right-0 group-even:left-0 top-0 !mt-0';
  return (
    <div className={`${defaultClassName} ${className}`} />
  )
}

function CardTitle({
  className,
  children,
}) {
  const defaultClassName = 'text-[50px] font-bold text-primary leading-[1.3]';

  return (
    <h4 className={`${defaultClassName} ${className}`}>
      {children}
    </h4>
  )
}

function WorkCard({
  excerpt,
  featureImage,
  link,
  odd,
  services,
  slug,
  title,
}: Props) {
  const { t } = useTranslation()

  return (
    <div
      className="work-card group grid even:text-right gap-8 text-2xl relative mt-16 first:mt-0 lg:mt-0"
      data-aos="drawing"
      data-aos-once
    >
      <CardTitle className="lg:hidden">{title}</CardTitle>
      <div className="grid lg:grid-cols-2 gap-8 pt-6 group-odd:pr-6 group-even:pl-6 lg:p-0 lg:pb-16 relative">
        <HLine className="h-line-mob lg:hidden group-odd:-ml-[20%] group-even:-mr-[20%] w-[120%]" />
        <div
          className="lg:group-even:order-1"
          data-aos={`fade-${odd ? 'right' : 'left'}`}
          data-aos-once
        >
          <GatsbyImage
            className="group-odd:-ml-[20%] group-even:-mr-[20%] w-[120%]"
            // @ts-ignore-next-line
            image={getImage(featureImage.localFile)}
            alt={featureImage.alternativeText}
          />
        </div>
        <div
          className="grid gap-8"
          data-aos={`fade-${odd ? 'left' : 'right'}`}
          data-aos-once
        >
          <CardTitle className="hidden lg:inline-block ">{title}</CardTitle>
          <div className="lg:pt-4 lg:pb-16 pl-6 lg:pr-6 space-y-3 relative">
            <HLine className="h-line hidden lg:block" />
            <BlockRichText data={excerpt.data.excerpt} />
            <div className="flex flex-wrap group-even:justify-end">
              {services.map(({ id, title }) => (
                <p key={`service-title-${id}`} className="underline mb-4 mr-4">
                  {title}
                </p>
              ))}
            </div>
            <div className="flex flex-wrap group-even:justify-end">
              { link.map(({
                  url,
                  type,
                  id,
                }) => (
                  <a key={`url-${id}`} className="mb-6 mr-6 hover:underline" href={url} target="_blank">
                    {t(workLinkLabel[type]) || `${replaceLink(url)}`}
                  </a>
                )
              )}
            </div>
            <Link className="button-main inline-block" to={`/work/${slug}`}>
              {t('view more')}
            </Link>
            <VLine className="v-line hidden lg:block" />
          </div>
        </div>
        <VLine className="v-line-mob lg:hidden" />
      </div>
    </div>
  )
}

export default WorkCard