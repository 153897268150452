import React, { Children, cloneElement, isValidElement, ReactNode } from 'react'

type Props = {
  children: ReactNode,
}

function SliderDots({
  children,
}: Props) {
  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      const { className: originalClassName } = child.props;
      const isActive = originalClassName.includes('slick-active');
      const className = [
        'slider-dot-button',
        isActive ? 'slider-dot-button-active' : ''
      ].join(' ');

      return cloneElement(child, {
        className,
      });
    }

    return child;
  })

  return (
    <div className="slider-dots my-6">
      <ul className="flex justify-center space-x-3">
        {childrenWithProps}
      </ul>
    </div>
  )
}

export function appendDots(dots) {
  return <SliderDots>{dots}</SliderDots>
}

export default SliderDots