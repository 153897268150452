import React, { MutableRefObject, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { StaticImage } from 'gatsby-plugin-image';
import ContactAddress from '../contact/contact-address';
import ContactPhone from '../contact/contact-phone';
import ContactEmail from '../contact/contact-email';
import * as yup from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormErrorMessages from '../form/form-error-messages';
import { formSubmitErrorMessage, useSendEmail } from '../form/email';
import { IconButton } from '../icon-link-button';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import FormSuccessMessage from '../form/form-success-message';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

type Props = {
  contactDetails: any;
  forwardRef?: MutableRefObject<any>;
}

const schema = yup.object({
  name: yup.string().required(),
  number: yup.string(),
  email: yup.string().email().required(),
  message: yup.string().required(),
});

function Form({
  forwardRef,
  loading,
  errors,
  onSubmit,
  register,
  visible,
}) {
  if (!visible) {
    return null;
  }

  const { t } = useTranslation()

  return (
    <>
      <FormErrorMessages {...{ errors }} />
      <form id="free-consultation-form" ref={forwardRef} className="flex flex-col space-y-4" onSubmit={onSubmit}>
        <p>{t('Interested in our services? Request for quotation and free consultation now! Feel free to call, email or use the contact form below.')}</p>
        <input
          className="form-input"
          type="text"
          placeholder={`${t('name')}*`}
          {...register('name')}
        />
        <input
          className="form-input"
          type="text"
          placeholder={`${t('number')}*`}
          {...register('number')}
        />
        <input
          className="form-input"
          type="email"
          placeholder={`${t('email')}*`}
          {...register('email')}
        />
        <textarea
          className="form-input"
          placeholder={`${t('message')}*`}
          rows={4}
          {...register('message')}
        />
        <p>
          {t('This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.')}
        </p>
        <div className="flex lg:justify-end">
          <IconButton
            type="submit"
            loading={loading}
            icon={faChevronRight}
          >
            {t('get free consult now')}
          </IconButton>
        </div>
      </form>
    </>
  )
}

function FreeConsultationForm({
  contactDetails,
  forwardRef,
}: Props) {
  const form = useRef()
  const { t } = useTranslation()

  const {
    formState: {
      errors,
    },
    setError,
    handleSubmit,
    register,
  }= useForm({
    resolver: yupResolver(schema),
  });
  
  const {
    send,
    submitting,
    success,
  } = useSendEmail({
    templateId: 'template_l62oxtn',
    errorCallback: () => setError('', { message: formSubmitErrorMessage }),
  })

  function onSubmit() {
    send(form.current)
  };

  return (
    <section ref={forwardRef} className="bg-concrete py-20 sm:text-xl form-section">
      <div className="container">
        <h2 className="section-title text-center md-6 md:mb-16">
          {t('free consultation')}
        </h2>
        <div className="grid lg:grid-cols-5 gap-20">
          <div className="order-last lg:order-first lg:col-span-2 space-y-4">
            <StaticImage
              className="mb-4"
              src="logos/logo-aa.png"
              alt="Logo AdvisoryApps"
            />
            <ContactAddress data={contactDetails.address.data.address} />
            <ContactPhone data={contactDetails.number.data.number} />
            <ContactEmail data={contactDetails.email.data.email} />
            <div className="free-consult-info-block pb-6 border-b-[1px] border-secondary/10">
              <span />
              <div className="space-x-3">
                <a
                  className="social-icon"
                  href="https://www.facebook.com/AdvisoryApps/"
                  aria-label="Facebook link"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  className="social-icon"
                  href="https://www.instagram.com/advisoryapps"
                  aria-label="Instagram link"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  className="social-icon"
                  href="https://www.linkedin.com/company/advisoryapps"
                  aria-label="Linkedin link"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a
                  className="social-icon"
                  href="https://twitter.com/AdvisoryApps"
                  aria-label="Twitter link"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </div>
            </div>
            <div>
              <StaticImage
                className="mb-8"
                src="logos/logo-gempak.png"
                alt="Logo Gempak"
                placeholder="tracedSVG"
              />
              <div>
                <strong>Gempak Sdn Bhd</strong>[1364820-M] is a subsidiary company of Advisory Apps Sdn Bhd [1176817-H]
              </div>
            </div>
          </div>
          <div className="lg:col-span-3">
            <FormSuccessMessage visible={success} />
            <Form {...{
              errors,
              forwardRef: form,
              onSubmit: handleSubmit(onSubmit),
              loading: submitting,
              register,
              visible: !success,
            }}/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FreeConsultationForm